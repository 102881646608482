<template>

	<!-- Platform Settings Card -->
	<a-card :bordered="false" class="header-solid h-full" :bodyStyle="{paddingTop: 0, paddingBottom: 0 }">
		<template #title>
			<h6 class="font-semibold m-0">تنظیمات پلتفرم</h6>
		</template>
		<ul class="list settings-list">
			<li>
				<h6 class="list-header text-sm text-muted">امنیت حساب کاربری</h6>
			</li>
			<li>
				<a-switch @change="toggleLoginEmail" :loading="loadingLoginEmail" v-model="email_logins" />
				<span>ارسال ایمیل زمان ورود به سامانه</span>
			</li>
			<li v-if="email_logins" transition="expand" style="padding-right: 20px;">
				<a-switch size="small" v-model="emailForAnswers"/>
				<span class="list-header text-sm text-muted">همراه با کد امنیتی جهت ورود به سیستم.</span>
			</li>
			<li>
				<a-switch v-model="emailForMentions" />
				<span>Email me when someone mentions me</span>
			</li>
			<li>
				<h6 class="list-header text-sm text-muted m-0">APPLICATION</h6>
			</li>
			<li>
				<a-switch v-model="emailForNewProjects" />
				<span>New launches and projects</span>
			</li>
			<li>
				<a-switch v-model="emailForProductUpdates" />
				<span>Monthly product updates</span>
			</li>
			<li>
				<a-switch v-model="emailForNewsletter" />
				<span>Subscribe to newsletter</span>
			</li>
		</ul>
	</a-card>
	<!-- / Platform Settings Card -->

</template>
<style >
/* always present */
.expand-transition {
  transition: all .3s ease;
  height: 30px;
  padding: 10px;
  background-color: #eee;
  overflow: hidden;
}
/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter, .expand-leave {
  height: 0;
  padding: 0 10px;
  opacity: 0;
}
</style>
<script>
	import { userService } from '../../services/user.service';
	import Vue from 'vue'

	export default ({
		methods: {
			toggleLoginEmail(checked) {
				this.loadingLoginEmail = true;
				if(checked)
				{
					// add meta
					userService.addMeta('S_mailonlogin', "on", true).then(response => {    
						if (response.status == 200) {
						return response.json();            
						} else {
						alert('asd');
						}
					}).then((response) => {

						this.loadingLoginEmail = false;
					}).catch((e) => {
						console.log(e);
					});

				}
				else
				{
					
					// add meta
					userService.addMeta('S_mailonlogin', "off", true).then(response => {    
						if (response.status == 200) {
							return response.json();            
						} else {
							alert('asd');
						}
					}).then((response) => {
						this.loadingLoginEmail = false;
					}).catch((e) => {
						console.log(e);
					});

				}
				console.log(`a-switch to ${checked}`);
			},
		},
		created(){
			this.user = Vue.prototype.$me;
			this.email_logins = this.user.meta.S_mailonlogin == 'on' ? true : false;
			this.secured = this.user.meta.S_mailonlogin == 'on' ? true : false;

		},
		data() {
			return {
				// Binded model property for "Platform Settings" switch button for "Email ... follows me" .
				email_logins: false,
				loadingLoginEmail: false,

				// Binded model property for "Platform Settings" switch button for "Email ... answers me" .
				emailForAnswers: false,

				// Binded model property for "Platform Settings" switch button for "Email ... mentions me" .
				emailForMentions: true,

				// Binded model property for "Platform Settings" switch button for "New launches and projects" .
				emailForNewProjects: true,

				// Binded model property for "Platform Settings" switch button for "Monthly product updates" .
				emailForProductUpdates: false,

				// Binded model property for "Platform Settings" switch button for "Subscribe to newsletter" .
				emailForNewsletter: true,
			}
		},
	})

</script>