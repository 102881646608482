<template>

	<!-- Profile Information Card -->
	<a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingLeft: 0,}">
		<template #title>
			<h6 class="font-semibold m-0">اطلاعات پروفایل</h6>
		</template>
		<a-button @click="() => (displayModifyModal())" type="link" slot="extra">
			<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
				<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
			</svg>

			<a-modal
				v-model="modifyModel"
				:title="'ویرایش اطلاعات کاربری'"
				centered
				@ok="() => (saveModifiedUser())"
				okText="ذخیره"
				:ok-button-props="{ props: { disabled: !formModified } }"
				cancelText="لغو"
			>
				<a-form  :form="form">
					<a-form-item>

					<a-row>
						<a-col :span="6">
							<a-upload
								list-type="picture-card"
								class="avatar-uploader"
								:customRequest="handleUpload"
								:show-upload-list="false"
								:before-upload="beforeUpload">
							<img :src="user.avatar" alt="avatar" style="max-width: 100%;border-radius: 10px;" />

							</a-upload>
						</a-col>
						<a-col :span="16" class="rtl" style="padding-right: 20px;">
							<a-form-item
								label="بیوگرافی"
							>
									<a-textarea
									name="bio"
										style="width:100%: margin: 10px;"
										v-model="user.meta.bio"
										placeholder="بنویسید"
										:auto-size="{ minRows: 3, maxRows: 5 }"
									/>
							</a-form-item>
						</a-col>
					</a-row>
					</a-form-item>
					<a-form-item
						:label-col="formItemLayout.labelCol"
						:wrapper-col="formItemLayout.wrapperCol"
						label="نام و نام خانوادگی"
					>
						<a-input
						v-decorator="[
							'fullname',
							{
								rules: [{ required: true, message: 'لطفا نام و نام خانوادگی کاربر را وارد کنید' }],
								initialValue: user.fullname
							},
							
						]"

						placeholder="بنویسید"
						/>
					</a-form-item>
					<a-form-item
						:label-col="formItemLayout.labelCol"
						:wrapper-col="formItemLayout.wrapperCol"
						label="نام کاربری"
					>
						<a-input disabled
						v-decorator="[
							'username',
							{
								initialValue: user.username
							},
						]"
						placeholder="Please input your name"
						class="ltr"
						/>
					</a-form-item>
					<a-form-item
						:label-col="formItemLayout.labelCol"
						:wrapper-col="formItemLayout.wrapperCol"
						label="آدرس ایمیل"
					>
						<a-input
						disabled
						v-decorator="[
							'email',
							{
								initialValue: user.email
							},
						]"
						class="ltr"
						placeholder="بنویسید"
						>
							<a @click="modifyEmail" slot="addonAfter">
							ویرایش
							</a>
						</a-input>
						
					</a-form-item>
					<a-form-item
						:label-col="formItemLayout.labelCol"
						:wrapper-col="formItemLayout.wrapperCol"
						label="شماره تلفن"
					>
						<a-input
						disabled
						v-decorator="[
							'phone_number',
							{
								initialValue: user.phone_number
							},
						]"
						class="ltr"
						placeholder="919123456"
						>
							<a slot="addonAfter">
							ویرایش
							</a>
						</a-input>
					</a-form-item>
 
				</a-form>

			
			</a-modal>

		</a-button>
		<p class="text-dark">
			{{user.meta.bio}}
		</p>
		<hr class="my-25">
		<a-descriptions :title="user.fullname" :column="1">
			<a-descriptions-item label="نام کاربری">
				<a tooltip="ویرایش" href="">
					<svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
						<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
					</svg>
				</a>
				{{user.username}}
			</a-descriptions-item>

			<a-descriptions-item label="شماره تلفن">
				<a-tag :color="user.meta.phone_confirmed == 'yes' ? 'green' : 'red'">
					<a tooltip="ویرایش">
						<svg style="position: relative; top : 3px;" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
							<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
						</svg>
					</a>
					{{user.phone_number}}
				</a-tag>
			</a-descriptions-item>
			<a-descriptions-item label="آدرس ایمیل">
				<a-tag :color="user.meta.email_confirmed == 'yes' ? 'green' : 'red'">
					<a tooltip="ویرایش">
						<svg style="position: relative; top : 3px;" width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
							<path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
						</svg>
					</a>
					{{user.email}}
				</a-tag>
			</a-descriptions-item>
			<a-descriptions-item label="حضور مجازی">
				<a href="#" class="mx-5 px-5">
					<a-icon type="twitter" style="color: #3EA1EC;" />
				</a>
				<a href="#" class="mx-5 px-5">
					<a-icon type="facebook" style="color: #344E86" />
				</a>
				<a href="#" class="mx-5 px-5">
					<a-icon type="instagram" style="color: #E1306C" />
				</a>
			</a-descriptions-item>
		</a-descriptions>
	</a-card>
	<!-- / Profile Information Card -->

</template>
<style scoped>
/* tile uploaded pictures */
.upload-list-inline >>> .ant-upload-list-item {
  float: left;
  margin-right: 8px;
}
.upload-list-inline >>> .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline >>> .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
</style>
<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}
	import { fileService } from '../../services/file.service';
	import { loginService } from '../../services/login.service';
	import Vue from 'vue'
import { userService } from '../../services/user.service';
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 15 },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};
	export default ({
		methods: {
			saveModifiedUser(e)
			{
				console.log(e);

			},
			handleChange(info) {
			if (info.file.status === 'uploading') {
				this.loading = true;
				this.handleUpload(info.file);
				return;
			}
			if (info.file.status === 'done') {
				// Get this url from response in real world.
				getBase64(info.file.originFileObj, imageUrl => {
					this.imageUrl = imageUrl;
					this.loading = false;
				});
			}
			},
			
			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('فقط فایل های JPG انتخاب کنید');
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('تصویر نباید بیشتر از 2MB باشد!');
				}
				return isJpgOrPng && isLt2M;
			},
			async handleUpload(info) {

				const formData = new FormData();

				formData.append( '_method', 'put' );
				formData.append( 'set', 'avatar' );

				formData.append( 'avatar', info.file );
	

				try {
					const response = await fileService.upload(formData, 'test');
					const data = response.data
					if(data.OK)
					{
						this.$message.success('تصویر با موفقیت بارگذاری شد!');

						if(data.avatar)
						{
							this.user.avatar = data.avatar
						}
					}
					else
					{
						this.$message.error('خطا:'+data.message);
					}
				} catch(e) {
					console.log('FAILURE!!');
				}

				
			},


			displayModifyModal(user) {


				this.modifyModel = true
			},
			modifyEmail(){
				console.log(this.modifyingEmail);
				if(!this.modifyingEmail)
					this.modifyingEmail = this.user.email;
				this.$confirm({
					title: 'فعال سازی شماره تلفن',
					content: (
					<div >
						<p 
							style="margin: 10px 10px 10px 0px;border-top: 1px solid red;border-radius: 10px;padding: 10px 15px 5px 5px;"
							if={this.user.meta.email_confirmed == 'no'}
							class='rtl'>
							<a-icon style='color: red; font-weight: bold' type="close-circle" /> آدرس ایمیل تایید نشده است
						</p>
						<div class='ltr'>
							<a-input v-model={this.modifyingEmail} default-value="91999999998">

								<a disabled={this.loading} vOn:click={this.send_activation_mail} slot="addonAfter">
									ارسال کد امنیتی
								</a>
							</a-input>
						</div>
					</div>
					),
					cancelText: 'لغو',
					okText: 'ثبت',
					okType: 'primary',
					cancelType: 'primary',
					okButtonProps: {
						props: { 
							disabled: this.modifyingEmail == this.user.email ? true :false,
						},
					},
					onOk() {
						return false;
					},
				});

			},
			activatePohne() {
				this.$error({
					title: 'فعال سازی شماره تلفن',
					content: (
					<div class='ltr'>

					<a-input addon-before="+98" value={this.user.phone_number} addon-after="ارسال کد امنیتی" default-value="91999999998" />

					</div>
					),
					cancelText: 'لغو',
					okText: 'ثبت',
					okType: 'danger',
					okButtonProps: {
						props: { 
						//	disabled: true
						},
					},
					onOk() {
						return false;
					},
				});
			},
			send_activation_mail() {

				if(!this.loading)
				{
					this.loading = true;
					userService.sendActivationMail(this.user.email).then(response => {    
						if (response.status == 200) {
							return response.json();            
						} else {
							alert('asd');
						}
					}).then((response) => {

						if(response.OK)
						{
							this.$message.success(response.message);

							if(response.avatar)
							{
								this.user.avatar = response.avatar
							}
						}
						else
						{
							this.$message.error('خطا:'+response.message);
						}
				
					}).catch((e) => {
						console.log(e);
					});
					var delay = ( function() {
						var timer = 0;
						return function(callback, ms) {
							clearTimeout (timer);
							timer = setTimeout(callback, ms);
						};
					})();
					console.log(this.loading);
					var that = this;
					delay(function(){
						alert('asd')
						that.loading = null;
						console.log(that.loading);
					}, 5000 ); // end delay
					console.log(this.loading);
				}
				else
				{
					this.$message.error('لطفا منتظر بمانید');
				}
			},
			modifyPhoneNumberModal(user) {
				this.modifyUser = user;
				this.selectedRoles = Object.values(user.roles)
				this.modifyPhoneModel = true
			},
		},
		created() {
			this.user = Vue.prototype.$me;
			// Registering window resize event listener to fix affix elements size
			// error while resizing.
			window.addEventListener("resize", this.resizeEventHandler);
		},
		data() {
			return {
				formModified: false,
				modifyUser: {},
				modifyingEmail: null,

				loading: null,

				modifyModel: null,
				formItemLayout,
				formTailLayout,
				form: this.$form.createForm(this, { 
				name: 'dynamic_rule' 
				}),
				fileList: [],
				uploading: false,
			}
		},
	})

</script>