import {config} from './config';

import axios from 'axios';
// Export methods
export const fileService = {
    upload,
}

// -------------------------------------------------

function upload(formData, Path) {
 
    const token = localStorage['token'];

    formData.append( 'path', Path );

    return axios.post(`${config.apiUrl}/user/files/upload`,
        formData,
        {
            headers: {
                'Authorization': 'Bearer '+token,
                'Content-Type': 'multipart/form-data'
            }
        }
    );


    return fetch( `${config.apiUrl}/user/files/upload`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer '+token,
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data'
        },
        body: formData
    } )
} 